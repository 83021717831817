import Vue from 'vue';
import Router from 'vue-router';
import store from '@/app/store';
import { TweenMax } from '@/vendor';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/',
            alias: [],
            name: 'Landing',
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/Landing' );
            },
            meta: {
                pageType: 'promotion-landing'
            }
        },

        {
            path: '/loten',
            name: 'Tickets',
            component: () =>
            {
                return import( /* webpackChunkName: "tickets" */ '@/app/pages/Tickets' );
            },
            meta: {
                pageType: 'promotion-leadform'
            }
        },

        {
            path: '/form',
            name: 'Naw',
            component: () =>
            {
                return import( /* webpackChunkName: "naw" */ '@/app/pages/Naw' );
            },

            beforeEnter: ( to, from, next ) =>
            {
                // router guard om te forceren dat je in de goede form route valt als er een AB test is.
                let formVersion = store.getters[ 'entry/all' ].formVersion;

                if( formVersion === 'B' && to.name !== 'FullForm' )
                {
                    next( '/form' );
                }
                else if( formVersion === 'A' && to.name === 'FullForm' )
                {
                    next( '/form/1' );
                }
                else
                {
                    next();
                }
            },

            children: [
                {
                    path: '/',
                    name: 'FullForm',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'form' */ '@/app/components/Form' );
                    }
                },
                {
                    path: '1',
                    name: 'Form1',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'form1' */ '@/app/pages/naw/Form1' );
                    },
                    meta: {
                        pageType: 'promotion-leadform'
                    }
                },
                {
                    path: '2',
                    name: 'Form2',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'form2' */ '@/app/pages/naw/Form2' );
                    },
                    meta: {
                        pageType: 'promotion-leadform'
                    }
                },
                {
                    path: '3',
                    name: 'Form3',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'form3' */ '@/app/pages/naw/Form3' );
                    },
                    meta: {
                        pageType: 'promotion-leadform'
                    }
                }
            ]
        },

        {
            path: '/form2',
            name: 'BehoudNaw',
            component: () =>
            {
                return import( /* webpackChunkName: "behoudnaw" */ '@/app/pages/BehoudNaw' );
            }
        },

        {
            path: '/overzicht',
            name: 'Overview',
            component: () =>
            {
                return import( /* webpackChunkName: "overview" */ '@/app/pages/Overview' );
            },
            beforeEnter: ( to, from, next ) =>
            {
                if( store.getters[ 'entry/all' ].id )
                {
                    window.location.reload();
                }
                next();
            },
            meta: {
                pageType: 'promotion-leadform'
            }
        },

        {
            path: '/bedankt',
            name: 'Confirmed',
            component: () =>
            {
                return import( /* webpackChunkName: "confirmed" */ '@/app/pages/Confirmed' );
            },
            meta: {
                pageType: 'promotion-confirmation'
            }
        },

        {
            path: '/scan',
            name: 'Scan',
            component: () =>
            {
                return import( /* webpackChunkName: "scan" */ '@/app/pages/Scan' );
            },
            meta: {
                pageType: 'promotion-landing'
            }
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( to.fullPath === '/bedankt' )
    {
        let entry = store.getters['entry/all'];

        dataLayer.push({
            event: 'confirmation',
            newsletter_optin: entry.kit,
            transaction_id: 'lotto-' + ( '000000' + entry.id ).substr( -6 ),
            aantal_loten: entry.tickets.length,
            XL: entry.xl ? 'Yes' : 'NO'
        });
    }

    let trackingAlias = {
        '/': '/landing',
        '/loten': '/loten',
        '/form': '/gegevens',
        '/form/1': '/gegevens-1',
        '/form/2': '/gegevens-2',
        '/form/3': '/gegevens-3',
        '/overzicht': '/overzicht',
        '/bedankt': '/bedankt'
    };

    dataLayer.push({
        event: 'pageview',
        page_path: trackingAlias[ to.fullPath ]
    });

    TweenMax.to( 'html, body', 0.5, { scrollTop: 0 });

    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
