import * as yup from 'yup';

export default {
    beforeCreate()
    {
        yup.setLocale({
            mixed: {
                required: obj => `${obj.label ? obj.label : obj.path} is verplicht` // `${obj.path} is default`
            },
            string: {
                min: obj => `${obj.path} moet minstens ${obj.min} karakters lang zijn`,
                matches: obj => `Dit is geen geldig ${obj.label}.`
            }
        });
    },

    data()
    {
        return {
            formConfig: {
                klantNr: {
                    label: 'Klantnummer',
                    name: 'klantnummer',
                    placeholder: '',
                    type: 'text',
                    tabindex: 1
                },
                emailaddress: {
                    label: 'E-mailadres',
                    name: 'emailaddress',
                    placeholder: 'bijv. info@stl.nl',
                    type: 'email',
                    tabindex: 2
                },

                kit: {
                    type: 'checkbox',
                    name: 'kit',
                    id: 'kit-checkbox',
                    tabindex: 3
                },

                telephone: {
                    label: 'Telefoonnummer',
                    name: 'telephone',
                    type: 'tel',
                    tabindex: 4
                },

                kit_phone: {
                    type: 'checkbox',
                    name: 'kit_phone',
                    id: 'kit_phone-checkbox',
                    tabindex: 5
                }
            },

            validationSchema: yup.object().shape({
                emailaddress: yup.string()
                    .label( 'e-mail' )
                    .required()
                    .email( 'Dit is geen valide mailadres' ),
                telephone: yup.string()
                    .label( 'telefoonnummer' )
                    .nullable()
                    /* eslint-disable-next-line */
                    .matches( /^((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/, { excludeEmptyString: true })
            })
        };
    }
};
