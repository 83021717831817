import Fingerprint2 from 'fingerprintjs2';

export default {
    mounted()
    {
        Fingerprint2.get( components =>
        {
            var values = components.map( component => component.value );
            this.entry.fingerprint = Fingerprint2.x64hash128( values.join( '' ), 31 );
        });
    }
};
