import Vue from 'vue';
import Vuex from 'vuex';

import entry from './modules/entry';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {
        entry
    },

    state: {
        // endTime: new Date( 2021, 1, 18 )
        endTime: new Date( 2020, 10, 16 )
    },

    getters: {
        endTime: state => state.endTime
    },

    mutations: {

    },

    actions: {

    }
});
